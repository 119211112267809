import { Box, Flex, Link } from 'rebass';
import React from 'react';
import styled from 'styled-components';

import BodyText from '../components/body-text';
import Layout from '../components/layout';
import PageHeading from '../components/page-heading';
import SEO from '../components/seo';

const Iframe = styled.iframe.attrs(() => ({
  src: 'https://adrexpedite.com/remcal/on/ian-anderson/blue',
}))`
  display: inline;
  float: left;
  height: 305px;
  width: 180px;
  border-style: none;
`;

const AvailabilityPage = () => (
  <Layout>
    <SEO title="Availability" />
    <PageHeading>Availability</PageHeading>
    <Flex>
      <Box flex="0 1 180px" mr={2}>
        <Iframe />
      </Box>
      <Box flex="1">
        <BodyText>
      Circles mark days on which Ian was available when this calendar was last
      updated.
        </BodyText>
        <BodyText>
          To confirm availability or book a date, please contact Ian at
          {' '}
          <Link href="mailto:Ian@AndersonArbitration.ca">Ian@AndersonArbitration.ca</Link>
          {' '}
          or
          {' '}
          <Link href="tel:4167296282">416 729-6282</Link>
        </BodyText>
      </Box>
    </Flex>
  </Layout>
);

export default AvailabilityPage;
